import React from 'react';
import { useNavigate } from 'react-router-dom';
import ObjektCard from './ObjektCard';

type Props = React.ComponentProps<typeof ObjektCard>;

/**
 * A container component for the ObjektCard component, which handles navigation.
 * This is useful for testing the ObjektCard component in isolation.
 */
const ObjektCardContainer = (props: Props) => {
  const navigate = useNavigate();

  const menuItems = [
    { label: 'Details', onClick: () => navigate(`/objekt/${props.objekt.id}`) },
  ];

  return <ObjektCard {...props} menuItems={menuItems} />;
};

export default ObjektCardContainer;
