import React from 'react';

type Props = {
  variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
  children: React.ReactNode;
};

const Alert = ({ variant, children }: Props) => {
  return (
    <div className={`alert alert-${variant}`} role='alert'>
      {children}
    </div>
  );
};

export default Alert;
