import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Row = ({ children, className = '' }: Props) => {
  return <div className={classNames('row', className)}>{children}</div>;
};

export default Row;
