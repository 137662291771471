import * as React from 'react';
import CircleFill from 'bootstrap-icons/icons/circle-fill.svg?react';
import styles from './UserStatus.module.scss';
import classNames from 'classnames';

type Props = {
  username: string;
  isOnline: boolean;
};

const UserStatus = ({ username, isOnline }: Props) => {
  return (
    <div>
      <div className={styles.username}>{username}</div>
      <div className={styles.statusRow}>
        <>
          <CircleFill
            className={classNames(styles.circle, {
              [`${styles.onlineColor}`]: isOnline,
              [`${styles.offlineColor}`]: !isOnline,
            })}
          />
          <span className={styles.statusText}>
            {isOnline ? 'Online' : 'Offline'}
          </span>
        </>
      </div>
    </div>
  );
};
export default UserStatus;
