import React, { ChangeEventHandler } from 'react';
import classNames from 'classnames';

type Props = {
  placeholder: string;
  ariaLabel?: string;
  className?: string;
  id: string;
  name: string;
  label: string;
  type?: 'text' | 'number' | 'date' | 'email' | 'tel' | 'password';
  value: string | number;
  onChange: ChangeEventHandler;
  error?: string;
  testId?: string;
  list?: string;
};

const Input = (props: Props) => {
  return (
    <div className='form-floating'>
      <input
        type={props.type ?? 'text'}
        className={classNames(
          'form-control',
          { 'is-invalid': props.error },
          props.className,
        )}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        aria-label={props.ariaLabel}
        data-testid={props.testId}
        list={props.list}
      />
      <label htmlFor={props.id} className={'form-label'}>
        {props.label}
      </label>
      {props.error && <div className='invalid-feedback'>{props.error}</div>}
    </div>
  );
};

export default Input;
