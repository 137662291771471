import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import ThreeDots from 'bootstrap-icons/icons/three-dots.svg?react';
import styles from './Dropdown.module.scss';

type ToggleProps = {
  onClick: () => void;
};

const CustomToggle = React.forwardRef<HTMLButtonElement, ToggleProps>(
  ({ onClick }, ref) => (
    <button
      ref={ref}
      onClick={onClick}
      className={classNames('btn', 'btn-light', 'btn-sm', styles.toggleBtn)}
    >
      <ThreeDots />
    </button>
  ),
);

CustomToggle.displayName = 'CustomToggle';

type Props = {
  children: ReactNode;
  direction?: 'up' | 'up-centered' | 'start' | 'end' | 'down' | 'down-centered';
  align?: 'start' | 'end';
};

const Dropdown: FC<Props> = ({
  children,
  direction = 'down',
  align = 'start',
}: Props) => {
  return (
    <BootstrapDropdown drop={direction} align={align}>
      <BootstrapDropdown.Toggle as={CustomToggle} />
      <BootstrapDropdown.Menu>{children}</BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
};

export default Dropdown;
