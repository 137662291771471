import { FormState } from '../AddBetriebForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from '../../../components/Row';
import Col from '../../../components/Col';
import Input from '../../../components/Input';
import FormFooter from '../FormFooter';

type Inputs = Pick<
  FormState,
  | 'betriebNameInput'
  | 'betriebStrasseInput'
  | 'betriebPlzInput'
  | 'betriebOrtInput'
>;

type Props = {
  formState: FormState;
  onAccept: (formState: FormState) => void;
  onCancel: () => void;
};

const validationSchema: Yup.ObjectSchema<Inputs> = Yup.object({
  betriebNameInput: Yup.string().required(),
  betriebStrasseInput: Yup.string().required(),
  betriebPlzInput: Yup.string().required(),
  betriebOrtInput: Yup.string().required(),
});

const BetriebForm = ({ formState, onAccept, onCancel }: Props) => {
  const { handleSubmit, handleChange, values, errors, touched } =
    useFormik<Inputs>({
      initialValues: {
        betriebNameInput: formState.betriebNameInput,
        betriebStrasseInput: formState.betriebStrasseInput,
        betriebPlzInput: formState.betriebPlzInput,
        betriebOrtInput: formState.betriebOrtInput,
      },
      onSubmit: (values) => {
        onAccept({ ...formState, ...values });
      },
      validationSchema,
    });
  return (
    <form onSubmit={handleSubmit} id='betriebForm'>
      <fieldset form='betriebForm' name='betrieb'>
        <legend>Betrieb</legend>
        <Row className='mt-3'>
          <Col>
            <Input
              placeholder='Name'
              id='betriebNameInput'
              name='betriebNameInput'
              value={values.betriebNameInput}
              onChange={handleChange}
              label='Name'
              error={
                errors.betriebNameInput && touched.betriebNameInput
                  ? errors.betriebNameInput
                  : undefined
              }
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Input
              placeholder='Straße'
              id='betriebStrasseInput'
              name='betriebStrasseInput'
              value={values.betriebStrasseInput}
              onChange={handleChange}
              label='Straße'
              testId='betriebStrasseInput'
              error={
                errors.betriebStrasseInput && touched.betriebStrasseInput
                  ? errors.betriebStrasseInput
                  : undefined
              }
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col size={4}>
            <Input
              placeholder='Plz.'
              id='betriebPlzInput'
              name='betriebPlzInput'
              value={values.betriebPlzInput}
              onChange={handleChange}
              label='Postleitzahl'
              testId='betriebPlzInput'
              error={
                errors.betriebPlzInput && touched.betriebPlzInput
                  ? errors.betriebPlzInput
                  : undefined
              }
            />
          </Col>
          <Col>
            <Input
              placeholder='Stadt'
              id='betriebOrtInput'
              name='betriebOrtInput'
              value={values.betriebOrtInput}
              onChange={handleChange}
              label='Stadt'
              testId='betriebOrtInput'
              error={
                errors.betriebOrtInput && touched.betriebOrtInput
                  ? errors.betriebOrtInput
                  : undefined
              }
            />
          </Col>
        </Row>
      </fieldset>
      <FormFooter
        acceptButtonLabel='Abschicken'
        backButtonOptions={{ label: 'Abbrechen', handleClick: onCancel }}
      />
    </form>
  );
};

export default BetriebForm;
