import * as React from 'react';
import { useAuth } from './authContext';
import axios from 'axios';

const FetchContext = React.createContext(axios.create());
const { Provider } = FetchContext;

type ProviderProps = {
  children: React.ReactNode;
};

const FetchProvider = ({ children }: ProviderProps) => {
  const { auth, logoutWithError } = useAuth();
  /** Axios instance to make requestst to protected API endpoints */
  const authAxios = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      if (auth) {
        config.headers.set('Authorization', `Bearer ${auth.token}`);
      }
      return config;
    },
    (error) => {
      // handle request error
      return Promise.reject(error);
    },
  );

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          logoutWithError(
            'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
          );
          return;
        }
      }
      return Promise.reject(error);
    },
  );

  return <Provider value={authAxios}>{children}</Provider>;
};

const useFetch = () => {
  const context = React.useContext(FetchContext);
  if (context === undefined) {
    throw new Error(`useFetch must be used within a FetchProvider`);
  }
  return context;
};

export { FetchProvider, useFetch };
