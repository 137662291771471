import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  size?: number | 'auto';
};

const Col = ({ children, className = '', size }: Props) => {
  const colClassName = size ? `col-${size}` : 'col';
  return <div className={classNames(colClassName, className)}>{children}</div>;
};

export default Col;
