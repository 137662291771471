import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from '../../components/Row';
import Col from '../../components/Col';
import Input from '../../components/Input';
import styles from './Login.module.scss';
import { useAuth } from '../../contexts/authContext';
import { Button } from 'react-bootstrap';
import Alert from '../../components/Alert/Alert';

type FormState = {
  usernameInput: string;
  passwordInput: string;
};

const validationSchema: Yup.ObjectSchema<FormState> = Yup.object({
  usernameInput: Yup.string().required(),
  passwordInput: Yup.string().required(),
});

const Login = () => {
  const { login, error } = useAuth();

  useEffect(() => {
    document.title = `${import.meta.env.VITE_DOCUMENT_TITLE} - Login`;
  }, []);

  const { handleSubmit, handleChange, values, errors, touched, setErrors } =
    useFormik<FormState>({
      initialValues: {
        usernameInput: '',
        passwordInput: '',
      },
      onSubmit: async ({ usernameInput, passwordInput }) => {
        try {
          await login(usernameInput, passwordInput);
        } catch (e) {
          setErrors({
            usernameInput: 'Falsches Passwort oder Nutzername',
            passwordInput: 'Falsches Passwort oder Nutzername',
          });
        }
      },
      validationSchema,
    });

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginFormOuter}>
        <form
          onSubmit={handleSubmit}
          id='loginForm'
          className={styles.loginForm}
        >
          <h1 className='text-center'>Anmeldung</h1>
          {error && (
            <div className='mt-3'>
              <Alert variant='danger'>{error}</Alert>
            </div>
          )}
          <Row>
            <Col>
              <Input
                placeholder='Nutzer:in'
                id='usernameInput'
                name='usernameInput'
                value={values.usernameInput}
                onChange={handleChange}
                label='Nutzer:in'
                error={
                  errors.usernameInput && touched.usernameInput
                    ? errors.usernameInput
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <Input
                placeholder='Passwort'
                id='passwordInput'
                name='passwordInput'
                value={values.passwordInput}
                onChange={handleChange}
                label='Passwort'
                error={
                  errors.passwordInput && touched.passwordInput
                    ? errors.passwordInput
                    : undefined
                }
                type='password'
              />
            </Col>
          </Row>
          <div className='d-grid mt-3 s'>
            <Button variant='primary' type='submit'>
              {'Login'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
