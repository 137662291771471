import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../contexts/fetchContext';
import Objekt, { DraftObjekt } from '../../models/Objekt';
import ObjektForm from './Steps/ObjektForm';

export type FormState = {
  objektNameInput: string;
  objektStrasseInput: string;
  objektPlzInput: string;
  objektOrtInput: string;
  betriebIdInput: string;
};

const AddObjektForm = () => {
  const [formState, setFormState] = useState<FormState>({
    betriebIdInput: '',
    objektNameInput: '',
    objektStrasseInput: '',
    objektPlzInput: '',
    objektOrtInput: '',
  });
  const authAxios = useFetch();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmitForm = async (formState: FormState) => {
    const objekt: DraftObjekt = {
      name: formState.objektNameInput,
      adresse: {
        strasse: formState.objektStrasseInput,
        plz: formState.objektPlzInput,
        ort: formState.objektOrtInput,
      },
      betriebId: parseInt(formState.betriebIdInput),
      betriebName: '',
      objektTyp: { value: 'BA', name: 'Baustelle' },
      datumBis: null,
      objektLeiter: null,
      notiz: null,
      stand: null,
      erschliessungsGrad: { id: 1 },
    };

    const basePath = '/objekte';

    const response = await authAxios.post<Objekt>(basePath, objekt);

    navigate('/');

    if (response.status !== 200) {
      throw Error(
        `Response status code was not 200. Instead was ${response.status}`,
      );
    }
  };

  return (
    <>
      <div className='container py2 flex-grow-1 d-flex flex-column'>
        <div className='flex-grow-1 d-flex flex-column justify-content-center'>
          <div>
            {
              <ObjektForm
                formState={formState}
                onAccept={handleSubmitForm}
                onCancel={handleCancel}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AddObjektForm;
