import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  variant?: 'primary' | 'secondary';
  type?: 'submit';
  onClick?: () => void;
  name?: string;
  id?: string;
  children: ReactNode;
  size?: 'sm' | 'lg';
};

const Button: FC<Props> = ({
  type,
  name,
  id,
  onClick,
  variant,
  children,
  size,
}: Props) => {
  return (
    <button
      type={type}
      name={name}
      id={id}
      onClick={onClick}
      className={classNames('btn', {
        [`btn-${variant}`]: variant,
        [`btn-${size}`]: size,
      })}
    >
      {children}
    </button>
  );
};

export default Button;
