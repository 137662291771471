import React, { useState } from 'react';
import Betrieb, { DraftBetrieb } from '../../models/Betrieb';
import BetriebForm from './Steps/BetriebForm';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../contexts/fetchContext';

export type FormState = {
  betriebNameInput: string;
  betriebStrasseInput: string;
  betriebPlzInput: string;
  betriebOrtInput: string;

  // Notizen //fehlt noch
};

const AddBetriebForm = () => {
  const [formState, setFormState] = useState<FormState>({
    betriebNameInput: '',
    betriebStrasseInput: '',
    betriebPlzInput: '',
    betriebOrtInput: '',
  });
  const authAxios = useFetch();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmitForm = async (formState: FormState) => {
    const betrieb: DraftBetrieb = {
      name: formState.betriebNameInput,
      adresse: {
        strasse: formState.betriebStrasseInput,
        plz: formState.betriebPlzInput,
        ort: formState.betriebOrtInput,
      },
      betriebTypId: 103, //Betriebtyp "Betrieb" ID hardgecoded hinzugefügt; Nochmal absprechen ob Auswahl gewünscht
    };

    const basePath = '/betriebe';

    const response = await authAxios.post<Betrieb>(basePath, betrieb);
    if (response.status == 201) {
      alert('Betrieb wurde erfolgreich hinzugefügt.');
    }
    navigate('/');

    if (response.status !== 200) {
      throw Error(
        `Response status code was not 200. Instead was ${response.status}`,
      );
    }
  };

  return (
    <>
      <div className='container py2 flex-grow-1 d-flex flex-column'>
        <div className='flex-grow-1 d-flex flex-column justify-content-center'>
          <div>
            {
              <BetriebForm
                formState={formState}
                onAccept={handleSubmitForm}
                onCancel={handleCancel}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBetriebForm;
