import React from 'react';
import Button from '../../components/Button/Button';
import Col from '../../components/Col';
import Row from '../../components/Row';

type Props = {
  acceptButtonLabel: string;
  backButtonOptions?: { label: string; handleClick: () => void };
};

const FormFooter = ({ acceptButtonLabel, backButtonOptions }: Props) => {
  return (
    <>
      <Row className='mt-5'>
        <Col className='d-grid gap-2'>
          {backButtonOptions && (
            <Button variant='secondary' onClick={backButtonOptions.handleClick}>
              {backButtonOptions.label}
            </Button>
          )}
        </Col>
      </Row>
      <Row className='mt-2 '>
        <Col className='d-grid gap-2'>
          <Button variant='primary' type='submit'>
            {acceptButtonLabel}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default FormFooter;
