import React, { FC, useEffect } from 'react';
import Button from '../../components/Button/Button';
import styles from './Dashboard.module.scss';
import ObjektOverview from '../../components/ObjektOverview/ObjektOverview';
import { useObjekte } from '../../contexts/objektContext';
import AddBetriebForm from '../AddBetriebForm/AddBetriebForm';
import { useNavigate } from 'react-router-dom';

const Dashboard: FC = () => {
  const { objekte, fetchAllObjekte, findObjekteByBetriebId, isLoading, error } =
    useObjekte();

  useEffect(() => {
    fetchAllObjekte();
  }, []);

  const navigate = useNavigate();

  const handleCreateBetrieb = () => {
    navigate('/betrieb/addBetrieb');
  };

  const handleCreateObjekt = () => {
    navigate('/objekt/addObjekt');
  };

  const searchObjekte = async (betriebId: string) => {
    if (betriebId === '') {
      fetchAllObjekte();
      return;
    }

    findObjekteByBetriebId(betriebId);
  };

  return (
    <div className={`container py-2 ${styles.home}`}>
      <div className={styles.headerRow}>
        <h1>{'Übersicht'}</h1>
        <Button variant='primary' onClick={handleCreateBetrieb}>
          {'Neuer Betrieb'}
        </Button>
        <Button variant='primary' onClick={handleCreateObjekt}>
          {'Neues Objekt'}
        </Button>
      </div>
      <hr />
      <div className={styles.content}>
        <ObjektOverview
          objekte={objekte}
          isLoading={isLoading}
          handleObjektSearch={(betriebId) => searchObjekte(betriebId)}
          error={error}
        />
      </div>
    </div>
  );
};

export default Dashboard;
