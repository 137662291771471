// a spinner component which displays a loading animation usind bootstrap's spinner
import React from 'react';
import classnames from 'classnames';

type Props = {
  /**
   * The size of the spinner
   * @default 'md'
   * */
  size?: 'sm' | 'md';

  /**
   * The color of the spinner
   * @default 'primary'
   * */
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';

  /**
   * Whether the spinner should be displayed as a border or as a grow spinner
   * @default 'border'
   * */
  type?: 'border' | 'grow';
};

/**
 * A spinner component which displays a loading animation using bootstrap's spinner
 * */
const Spinner = ({
  size = 'md',
  color = 'primary',
  type = 'border',
}: Props) => {
  const spinnerClasses = classnames(
    `text-${color}`,
    { 'spinner-border': type === 'border' },
    { 'spinner-grow': type === 'grow' },
    { [`spinner-border-${size}`]: type === 'border' },
    { [`spinner-grow-${size}`]: type === 'grow' },
  );

  return (
    <div className={spinnerClasses} role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  );
};

export default Spinner;
