import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import styles from './Drawer.module.scss';
type Props = {
  show: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
};

const Drawer = ({ show, handleClose, title, children }: Props) => {
  return (
    <Offcanvas className={styles.offcanvas} show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.offcanvasBody}>
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Drawer;
