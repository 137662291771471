import React, { useEffect, useState } from 'react';
import ObjektDetails from '../../components/ObjektDetails/ObjektDetails';
import { useObjekte } from '../../contexts/objektContext';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../contexts/fetchContext';
import Besuch from '../../models/Besuch';

const ObjektDetailsContainer = () => {
  const [besuche, setBesuche] = useState<Besuch[]>([]);
  const authAxios = useFetch();
  const { objekte } = useObjekte();
  const { objektId } = useParams();

  useEffect(() => {
    const getBesuche = async () => {
      const response = await authAxios.get<Besuch[]>(
        `/objekte/${objektId}/besuche`,
      );
      setBesuche(response.data);
    };

    getBesuche();
  }, [objektId]);

  const objekt = objekte.find((objekt) => objekt.id === Number(objektId));

  if (!objekt) {
    return <p>Objekt nicht gefunden</p>;
  }

  return <ObjektDetails objekt={{ ...objekt, besuche: [...besuche] }} />;
};

export default ObjektDetailsContainer;
