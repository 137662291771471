import React from 'react';
import Objekt from '../../models/Objekt';
import classnames from 'classnames';
import styles from './ObjektCard.module.scss';
import Dropdown from '../Dropdown/Dropdown';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';

type Props = {
  objekt: Objekt;
  menuItems?: { label: string; onClick: () => void }[];
};

/**
 * A card component which displays a quick summary of a objekt
 */
const ObjektCard = ({ objekt, menuItems }: Props) => {
  return (
    <div className={classnames('card', 'bg-light')}>
      <div className='card-header'>
        <div className={styles.headerRow}>
          <div>{objekt.betriebName}</div>
          {menuItems && (
            <div className={styles.dropdown}>
              <Dropdown align='end' direction='down'>
                {menuItems.map((item) => (
                  <BootstrapDropdown.Item
                    key={item.label}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </BootstrapDropdown.Item>
                ))}
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <div className='card-body'>
        <div className={classnames(styles.cardTitle, 'card-title')}>
          {objekt.name}
        </div>
        <span className='card-subtitle text-muted'>{objekt.id}</span>
      </div>
      <ul className='list-group list-group-flush'>
        <li className='list-group-item'>
          Adresse:{' '}
          {`${objekt.adresse.strasse}, ${objekt.adresse.plz} ${objekt.adresse.ort}`}
        </li>
        <li className='list-group-item'>
          Typ: {objekt?.objektTyp?.name ?? ' - '}
        </li>
        <li className='list-group-item'>
          Aktiv bis:{' '}
          {objekt.datumBis
            ? new Date(objekt.datumBis).toLocaleDateString('de-DE')
            : ' - '}
        </li>
      </ul>
    </div>
  );
};

export default ObjektCard;
