import React from 'react';
import styles from './ObjektOverview.module.scss';
import Objekt from '../../models/Objekt';
import Row from '../Row';
import Col from '../Col';
import SearchBar from '../SearchBar/SearchBar';
import ObjektCardContainer from '../ObjektCard/ObjektCardContainer';
import Spinner from '../Spinner/Spinner';
import Alert from '../Alert/Alert';
import type { Error } from '../../models/Error';
import utilityStyles from '../../Utils.module.scss';
type Props = {
  objekte: Objekt[];
  isLoading: boolean;
  handleObjektSearch: (searchTerm: string) => void;
  error?: Error;
};

const ObjektOverview = ({
  objekte,
  handleObjektSearch,
  isLoading,
  error,
}: Props) => {
  return (
    <>
      <Row className={styles.headerRow}>
        <Col>
          <h2 className={styles.header}>Baustellen</h2>
        </Col>
        <Col size={'auto'}>
          <SearchBar
            placeholder='Betr-Nr'
            handleSearch={handleObjektSearch}
            disabled={isLoading}
          />
        </Col>
      </Row>
      {error && <Alert variant='danger'>{error.message}</Alert>}
      {isLoading ? (
        <div className='d-flex justify-content-center'>
          <Spinner />
        </div>
      ) : objekte.length === 0 ? (
        <Alert variant='info'>Keine Baustellen gefunden</Alert>
      ) : (
        <div className={styles.gridContainer}>
          {objekte.map((objekt) => (
            <ObjektCardContainer objekt={objekt} key={objekt.id} />
          ))}
        </div>
      )}
    </>
  );
};

export default ObjektOverview;
