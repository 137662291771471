import React from 'react';

type Props = {
  /**
   * Current step, as in 'step 1 of 5'
   */
  now: number;
  /**
   * Max step
   */
  max: number;
};

const Progressbar = ({ now, max }: Props) => {
  const progressInPercent = Math.floor((now / max) * 100);

  return (
    <div className='progress'>
      <div
        className='progress-bar'
        style={{ width: `${progressInPercent}%` }}
        role='progressbar'
        aria-valuenow={progressInPercent}
        aria-valuemin={0}
        aria-valuemax={100}
      ></div>
    </div>
  );
};

export default Progressbar;
