import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

type Props = {
  href: string;
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  children: ReactNode;
};

/**
 * A link which looks like a button
 */
const LinkButton = ({ href, variant, children, onClick }: Props) => {
  return (
    <Link
      to={href}
      onClick={onClick}
      className={classNames('btn', { [`btn-${variant}`]: variant })}
      role='button'
    >
      {children}
    </Link>
  );
};

export default LinkButton;
