import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/authContext';
import { ObjekteProvider } from './contexts/objektContext';
import { FetchProvider } from './contexts/fetchContext';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <FetchProvider>
          <ObjekteProvider>
            <App />
          </ObjekteProvider>
        </FetchProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
