import React from 'react';
import classNames from 'classnames';
import styles from './Header.module.scss';
import logo from '../../assets/img/logo_square.png';
import menuLogo from '../../assets/img/three-bars.svg';

import Button from '../Button/Button';

type Props = {
  handleLogoButtonClick: () => void;
};

const Header = ({ handleLogoButtonClick }: Props) => {
  return (
    <header className={classNames('p-2', 'container-md', styles.header)}>
      <div className='d-flex flex-wrap align-items-center justify-content-between justify-content-md-between'>
        <div>
          <img src={logo} className={styles.logo} />
        </div>
        <Button onClick={handleLogoButtonClick}>
          <img
            src={menuLogo}
            className={styles.menuIcon}
            alt='Öffne Navigation'
          />
        </Button>
      </div>
    </header>
  );
};

export default Header;
