import * as React from 'react';
import styles from './NotFound.module.scss';
import utilityStyles from '../../Utils.module.scss';

const NotFound = () => {
  return (
    <div className={utilityStyles.centered}>
      <h1 className={styles.header}>404</h1>
      <p>Die Seite konnte nicht gefunden werden</p>
    </div>
  );
};

export default NotFound;
