import { useFormik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import Input from '../../../components/Input';
import { FormState } from '../AddObjektForm';
import FormFooter from '../../AddBetriebForm/FormFooter';

type Inputs = Pick<
  FormState,
  | 'objektNameInput'
  | 'objektStrasseInput'
  | 'objektPlzInput'
  | 'objektOrtInput'
  | 'betriebIdInput'
>;

type Props = {
  formState: FormState;
  onAccept: (formState: FormState) => void;
  onCancel: () => void;
};

const validationSchema: Yup.ObjectSchema<Inputs> = Yup.object({
  objektNameInput: Yup.string().required(),
  objektStrasseInput: Yup.string().required(),
  objektPlzInput: Yup.string().required(),
  objektOrtInput: Yup.string().required(),
  betriebIdInput: Yup.string().required(),
});

const ObjektForm = ({ formState, onAccept, onCancel }: Props) => {
  const { handleSubmit, handleChange, values, errors, touched } =
    useFormik<Inputs>({
      initialValues: {
        objektNameInput: formState.objektNameInput,
        objektStrasseInput: formState.objektStrasseInput,
        objektPlzInput: formState.objektPlzInput,
        objektOrtInput: formState.objektOrtInput,
        betriebIdInput: formState.betriebIdInput,
      },
      onSubmit: (values) => {
        onAccept({ ...formState, ...values });
      },
      validationSchema,
    });
  return (
    <form onSubmit={handleSubmit} id='objektForm'>
      <fieldset form='objektForm' name='objekt'>
        <legend>Objekt</legend>
        <Row className='mt-3'>
          <Col>
            <Input
              placeholder='Betriebs-ID'
              id='betriebIdInput'
              name='betriebIdInput'
              value={values.betriebIdInput}
              onChange={handleChange}
              label='Betriebs-ID'
              error={
                errors.betriebIdInput && touched.betriebIdInput
                  ? errors.betriebIdInput
                  : undefined
              }
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Input
              placeholder='Name'
              id='objektNameInput'
              name='objektNameInput'
              value={values.objektNameInput}
              onChange={handleChange}
              label='Name'
              error={
                errors.objektNameInput && touched.objektNameInput
                  ? errors.objektNameInput
                  : undefined
              }
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Input
              placeholder='Straße'
              id='objektStrasseInput'
              name='objektStrasseInput'
              value={values.objektStrasseInput}
              onChange={handleChange}
              label='Straße'
              testId='objektStrasseInput'
              error={
                errors.objektStrasseInput && touched.objektStrasseInput
                  ? errors.objektStrasseInput
                  : undefined
              }
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col size={4}>
            <Input
              placeholder='Plz.'
              id='objektPlzInput'
              name='objektPlzInput'
              value={values.objektPlzInput}
              onChange={handleChange}
              label='Postleitzahl'
              testId='objektPlzInput'
              error={
                errors.objektPlzInput && touched.objektPlzInput
                  ? errors.objektPlzInput
                  : undefined
              }
            />
          </Col>
          <Col>
            <Input
              placeholder='Stadt'
              id='objektOrtInput'
              name='objektOrtInput'
              value={values.objektOrtInput}
              onChange={handleChange}
              label='Stadt'
              testId='objektOrtInput'
              error={
                errors.objektOrtInput && touched.objektOrtInput
                  ? errors.objektOrtInput
                  : undefined
              }
            />
          </Col>
        </Row>
      </fieldset>
      <FormFooter
        acceptButtonLabel='Abschicken'
        backButtonOptions={{ label: 'Abbrechen', handleClick: onCancel }}
      />
    </form>
  );
};

export default ObjektForm;
