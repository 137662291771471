import React, { useState } from 'react';
import Search from 'bootstrap-icons/icons/search.svg?react';

type Props = {
  handleSearch: (searchTerm: string) => void;
  placeholder: string;
  disabled?: boolean;
};

const SearchBar = ({ handleSearch, placeholder, disabled = false }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  return (
    <div className='input-group'>
      <input
        type='text'
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className='form-control border-primary'
        disabled={disabled}
      />
      <button
        className='btn btn-outline-primary'
        onClick={() => handleSearch(searchTerm)}
        disabled={disabled}
      >
        <Search />
      </button>
    </div>
  );
};

export default SearchBar;
