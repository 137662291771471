import React from 'react';
import Objekt from '../../models/Objekt';
import Row from '../Row';
import Col from '../Col';
import styles from './ObjektDetails.module.scss';

type Props = {
  objekt: Objekt;
};

const ObjektDetails = ({ objekt }: Props) => {
  return (
    <div className='container mt-5'>
      <Row>
        <Col>
          <h3 className={styles.heading}>Allgemein</h3>
          <ul className={styles.list}>
            <li>Name: {objekt.name}</li>
            <li>
              Adresse: {objekt.adresse.strasse}, {objekt.adresse.plz}{' '}
              {objekt.adresse.ort}
            </li>
            <li>Typ: {objekt.objektTyp?.name ?? ' - '}</li>
            <li>ID: {objekt.id}</li>
          </ul>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <h3 className={styles.heading}>Betrieb</h3>
          <ul className={styles.list}>
            <li>ID: {objekt.betriebId}</li>
            <li>Name: {objekt.betriebName ?? ' - '}</li>
          </ul>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col>
          <h3 className={styles.heading}>Ansprechpartner:innen</h3>
          <ul className={styles.list}>
            <li>Polier: {objekt.objektLeiter?.name ?? ' - '}</li>
          </ul>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col>
          <h3 className={styles.heading}>Besuche</h3>
          {objekt.besuche && objekt.besuche.length > 0 ? (
            <ul className={styles.list}>
              {objekt.besuche.map((besuch) => (
                <li key={besuch.notes}>
                  {`[${besuch.date}] `}
                  {besuch.notes}
                  {` (${besuch.member?.name ?? 'Unbekannt'})`}
                </li>
              ))}
            </ul>
          ) : (
            <>{'Keine Besuche'}</>
          )}
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col>
          <h3 className={styles.heading}>Notizen</h3>
          {objekt.notiz ? <>{objekt.notiz}</> : <>{'Keine Notizen'}</>}{' '}
        </Col>
      </Row>
    </div>
  );
};

export default ObjektDetails;
