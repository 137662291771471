import React from 'react';
import Objekt from '../models/Objekt';
import axios, { AxiosError } from 'axios';
import type { Error } from '../models/Error';
import { useFetch } from './fetchContext';

type ObjektContextState = {
  objekte: Objekt[];
  isLoading: boolean;
  error?: Error;
  fetchAllObjekte: () => void;
  findObjekteByBetriebId: (betriebId: string) => void;
};

const ObjekteContext = React.createContext<ObjektContextState>({
  objekte: [],
  isLoading: false,
  error: undefined,
  fetchAllObjekte: () => {
    console.log('default fetchAllObjekte');
  },
  findObjekteByBetriebId: (_: string) => {
    console.log('default findObjekteByBetriebId');
  },
});

const { Provider } = ObjekteContext;

type ProviderProps = {
  children: React.ReactNode;
};

const ObjekteProvider = ({ children }: ProviderProps) => {
  const authAxios = useFetch();

  const [objektState, setObjektState] = React.useState<
    Pick<ObjektContextState, 'objekte' | 'isLoading' | 'error'>
  >({
    objekte: [],
    isLoading: false,
    error: undefined,
  });

  const path = '/objekte';

  const fetchAllObjekte = async () => {
    setObjektState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const objekteResponse = await authAxios.get<Objekt[]>(path);
      setObjektState({
        objekte: objekteResponse.data,
        isLoading: false,
        error: undefined,
      });
    } catch (fetchError) {
      if (axios.isAxiosError(fetchError)) {
        handleAxiosError(fetchError);
      }
    } finally {
      setObjektState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const findObjekteByBetriebId = async (betriebId: string) => {
    setObjektState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const response = await authAxios.get<Objekt[]>(path, {
        params: { betriebId },
      });
      setObjektState({
        objekte: response.data,
        isLoading: false,
        error: undefined,
      });
    } catch (fetchError) {
      if (axios.isAxiosError(fetchError)) {
        handleAxiosError(fetchError);
      }
    } finally {
      setObjektState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAxiosError = (error: AxiosError<any, any>) => {
    const errorMessage =
      error.response?.data?.['error_message'] ?? error.message;
    const errorCode = error.response?.status;

    setObjektState((prevState) => ({
      ...prevState,
      error: { message: errorMessage, code: errorCode },
    }));
  };

  return (
    <Provider
      value={{
        ...objektState,
        fetchAllObjekte,
        findObjekteByBetriebId: findObjekteByBetriebId,
      }}
    >
      {children}
    </Provider>
  );
};

const useObjekte = () => {
  const context = React.useContext(ObjekteContext);
  if (context === undefined) {
    throw new Error(`useObjekte must be used within an ObjektProvider`);
  }
  return context;
};

export { ObjekteProvider, useObjekte };
