import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

type Props = {
  children: React.ReactNode;
};

const AuthRoute = ({ children }: Props) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to='/login' replace={true} />;
  }

  return children;
};

export default AuthRoute;
