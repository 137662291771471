import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginResponse } from '../models/Login';
import axios from 'axios';

export type AuthData = {
  token: string;
  expiresAt: number;
  username: string;
};

type AuthContextState = {
  auth: AuthData | null;
  isAuthenticated: () => boolean;
  error: string | null;
  login: (username: string, password: string) => void;
  logout: () => void;
  logoutWithError: (error: string) => void;
  setAuthError: (error: string) => void;
};

// the defaultValue will only be used by components who do not have a matching
// Provider above them in the tree (eg. when testing in isolation)
const AuthContext = createContext<AuthContextState>({
  auth: {
    token: 'mock-token-for-testing',
    expiresAt: Date.now() + 1000 * 60 * 5,
    username: 'testuser',
  },
  isAuthenticated: () => true,
  error: null,
  login: (username: string, password: string) =>
    console.log(`Login mock: username: ${username}, password: ${password}`),
  logout: () => console.log('Logout mock'),
  logoutWithError: (error) => console.log(`Logout with error mock: ${error}`),
  setAuthError(error) {
    console.log(`Set auth error mock: ${error}`);
  },
});

const { Provider } = AuthContext;

type ProviderProps = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: ProviderProps) => {
  const navigate = useNavigate();

  const auth = localStorage.getItem('auth');

  const [authState, setAuthState] = useState<
    Pick<AuthContextState, 'auth' | 'error'>
  >({
    auth: auth ? JSON.parse(auth) : null,
    error: null,
  });

  const isAuthenticated = () => {
    if (!authState.auth) {
      return false;
    }

    return authState.auth.expiresAt > Date.now();
  };

  const login = async (username: string, password: string) => {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    const response = await axios.post<LoginResponse>(
      `${import.meta.env.VITE_API_BASE_URL}/login`,
      params,
    );

    const loginResponse = response.data;

    const auth: AuthData = {
      token: loginResponse.access_token,
      expiresAt: loginResponse.expires_at,
      username: loginResponse.username,
    };

    localStorage.setItem('auth', JSON.stringify(auth));
    setAuthState({ auth, error: null });

    navigate('/');
  };

  const logout = () => {
    localStorage.removeItem('auth');
    setAuthState({ auth: null, error: null });

    navigate('/login');
  };

  const logoutWithError = (error: string) => {
    localStorage.removeItem('auth');
    setAuthState({ auth: null, error });

    navigate('/login');
  };

  const setAuthError = (error: string) => {
    setAuthState({ ...authState, error });
  };

  return (
    <Provider
      value={{
        ...authState,
        login,
        logout,
        logoutWithError,
        setAuthError,
        isAuthenticated,
      }}
    >
      {children}
    </Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
