import { useState, useEffect } from 'react';
import Login from './pages/Login/Login';
import {
  Route,
  Routes,
  useLocation,
  matchPath,
  Navigate,
} from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import ObjektDetailsContainer from './pages/ObjektDetailsContainer/ObjektDetailsContainer';
import NotFound from './pages/NotFound/NotFound';
import AppShell from './components/AppShell/AppShell';
import Objekt from './models/Objekt';
import AddBetriebForm from './pages/AddBetriebForm/AddBetriebForm';
import AddObjektForm from './pages/AddObjektForm/AddObjektForm';

// map of path patterns to page titles
const pageTitles = {
  '/login': 'Login',
  '/dashboard': 'Übersicht',
  '/betrieb/addBetrieb': 'Neuer Betrieb',
  '/objekt/addObjekt': 'Neues Objekt',
  '/objekt/:objektId': 'Baustelle',
  '/404': '404',
  '/': 'Übersicht',
};

const App = () => {
  const { pathname } = useLocation();
  const [progressNow, setProgressNow] = useState<number>();
  const [progressMax, setProgressMax] = useState<number>();
  const [objektToEdit] = useState<Objekt | undefined>();

  // update document title on route change
  useEffect(() => {
    for (const [pattern, title] of Object.entries(pageTitles)) {
      const match = matchPath(pattern, pathname);
      if (match) {
        document.title = `${import.meta.env.VITE_DOCUMENT_TITLE} - ${title}`;
        return;
      }
    }

    document.title = `${import.meta.env.VITE_DOCUMENT_TITLE}`;
  }, [pathname]);

  const handleUpdateObjekt = async (_newObjekt: Objekt) => {
    /*   const updatedSite = await updateSite(newSite)
    setSites(sites.map(site => site.id !== updatedSite.id ? site : updatedSite)) */
  };

  const handleCreateObjekt = async (_newObjekt: Objekt) => {
    /*  const createdSite = await createSite(newSite)
    setSites([...sites, createdSite])*/
  };

  const handleSetProgress = (progressConf?: { now: number; max: number }) => {
    if (progressConf) {
      setProgressNow(progressConf.now);
      setProgressMax(progressConf.max);
    } else {
      setProgressNow(undefined);
      setProgressMax(undefined);
    }
  };

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route
        path='/'
        element={
          <AuthRoute>
            <AppShell progressNow={progressNow} progressMax={progressMax} />
          </AuthRoute>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='betrieb/addBetrieb' element={<AddBetriebForm />} />
        <Route path='objekt/:objektId' element={<ObjektDetailsContainer />} />
        <Route path='objekt/addObjekt' element={<AddObjektForm />} />
        <Route path='404' element={<NotFound />} />
        <Route path='*' element={<Navigate to='/404' replace={true} />} />
      </Route>
    </Routes>
  );
};

export default App;
