import React from 'react';
import Header from '../Header/Header';
import Progressbar from '../Progressbar/Progressbar';
import Drawer from '../Drawer/Drawer';
import LinkButton from '../Button/LinkButton';
import Button from '../Button/Button';
import { useAuth } from '../../contexts/authContext';
import styles from './AppShell.module.scss';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import UserStatus from '../UserStatus/UserStatus';

type Props = {
  progressNow?: number;
  progressMax?: number;
};

const AppShell = ({ progressNow, progressMax }: Props) => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const { logout, auth } = useAuth();
  return (
    <div className={classNames('App', styles.app)}>
      <div className={styles.headerContainer}>
        <Header handleLogoButtonClick={() => setShowDrawer(!showDrawer)} />
        {progressNow && progressMax && (
          <Progressbar now={progressNow} max={progressMax} />
        )}
      </div>

      <main className={styles.main}>
        {/* The actual page/content of the app is rendered by the Outlet component */}
        <Outlet />
      </main>

      <Drawer
        show={showDrawer}
        title={'Menü'}
        handleClose={() => setShowDrawer(false)}
      >
        <UserStatus username={auth?.username ?? ''} isOnline={true} />
        <hr />
        <nav>
          <ul>
            <li>
              <LinkButton
                href='/dashboard'
                onClick={() => setShowDrawer(false)}
                variant='primary'
              >
                {'Zur Übersicht'}
              </LinkButton>
            </li>
            <li>
              <Button
                variant='secondary'
                onClick={() => {
                  setShowDrawer(false);
                  logout();
                }}
              >
                {'Logout'}
              </Button>
            </li>
          </ul>
        </nav>
      </Drawer>
    </div>
  );
};

export default AppShell;
